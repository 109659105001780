@mixin mq($mq) {
  @if $mq == retina {
    @media screen and (min-resolution: 2dppx) {
      @content;
    }
  } @else if $mq == print {
    @media print {
      @content;
    }
  } @else {
    @media all and (min-width: $mq) {
      @content;
    }
  }
}

// Return an em value based on the $base-font-size or contextual size. Credit to @ijy https://gist.github.com/ijy/1441967
@function em($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0em;
}

// Calculate a rem font size. If $line-height is true, set a line height equal to a multiple of the rhythm unit. Thanks @csswizardry & @redclov3r: https://github.com/csswizardry/inuit.css/blob/7dbc892f8d298795e19aeb64d1369ae3271561d1/generic/_mixins.scss
@mixin font-size($font-size, $line-height: false) {
  font-size: ($font-size / $base-font-size) * 1rem;
  @if $line-height != false {
    line-height: ceil($font-size / $rhythm-unit) * ($rhythm-unit / $font-size);
  }
}

@mixin scrimGradient($startColor: "black", $direction: "to bottom") {
  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0,
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue)
      percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);
}

$lw-green: #70be49;
$lw-green-medium: #558b41;

$revealStartWidthSm: 182px;
$revealEndWidthSm: 900px;
$revealWidthDifferenceSm: $revealEndWidthSm - $revealStartWidthSm;
$revealAnimShiftSm: $revealWidthDifferenceSm/2;
$revealBorderWidthSm: 62px;
$revealTopOffsetSm: 240px;
$topBackgroundImageSm: 140px;

$topBackgroundImageMed: 150px;
$revealStartWidthMed: 260px;
$revealEndWidthMed: 1200px;
$revealWidthDifferenceMed: $revealEndWidthMed - $revealStartWidthMed;
$revealAnimShiftMed: $revealWidthDifferenceMed/2;
$revealBorderWidthMed: 86px;
$revealTopOffsetMed: 200px;

$topBackgroundImage: 150px;
$revealStartWidth: 388px;
$revealEndWidth: 1920px;
$revealWidthDifference: $revealEndWidth - $revealStartWidth;
$revealAnimShift: $revealWidthDifference/2;
$revealBorderWidth: 130px;
$revealTopOffset: 300px;
$revealSpeed: 0.3s;
$revealSpeedHalf: $revealSpeed/2;
$revealSpeedTwoThirds: $revealSpeed * 0.66;
$revealDelay: 1s;
$revealEase: ease-in-out;
$revealEaseIn: ease-in;
$revealEaseOut: ease-out;

$base-line-height: 30;
$base-font-size: 16;
$rhythm-unit: $base-line-height/2; // 15

$lap-start-pixels: 640;
$desk-start-pixels: 900;
$desk-wide-start-pixels: 1200;

$lap-start: $lap-start-pixels + 0px;
$desk-start: $desk-start-pixels + 0px;
$desk-wide-start: $desk-wide-start-pixels + 0px;

*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  background-color: #fff;
}
.hero {
  background-color: #fff;
  position: relative;
  overflow: hidden;

  // Fade to white on the edges
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200px;
    z-index: 3;
  }
  &:before {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+); // IE9
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    left: 50%;
    transform: translateX(-($revealEndWidthSm/2));
  }
  &:after {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+); //IE9
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    right: 50%;
    transform: translateX($revealEndWidthSm/2);
  }

  @include mq($desk-start) {
    &:before {
      transform: translateX(-($revealEndWidthMed/2));
    }
    &:after {
      transform: translateX($revealEndWidthMed/2);
    }
  }

  @include mq($desk-wide-start) {
    &:before {
      transform: translateX(-($revealEndWidth/2));
    }
    &:after {
      transform: translateX($revealEndWidth/2);
    }
  }
}
.bg-wrapper {
  position: relative;
  width: 100%;
  height: 506px;
  overflow: hidden;

  @include mq($desk-start) {
    height: 674px;
  }
  @include mq($desk-wide-start) {
    height: 1080px;
  }
}
.bg-bottomfade {
  content: "";
  display: block;
  position: absolute;
  width: 100vw;
  height: 100px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2UwZThmMiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlMGU4ZjIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+); //IE9
  @include scrimGradient(#fff, "to top");
  z-index: 4;

  @include mq($desk-start) {
    height: 110px;
  }
  @include mq($desk-wide-start) {
    height: 175px;
  }
}
.bg-color,
.bg-blurred,
.bg-reveal {
  background-repeat: no-repeat;
  background-position-x: center;
}
.bg-color,
.bg-blurred {
  width: 100%;
  max-width: 1920px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;

  @include mq($desk-start) {
    height: 100vh;
  }
  @media all and (min-width: $desk-wide-start) and (min-height: 700px) {
    height: 100vh;
  }
}
.bg-color,
.bg-reveal {
  background-image: url("./assets/images/img_reveal_mobile.jpg");
  background-position-y: $topBackgroundImageSm; // POSITION Y WIDE DESKTOP
  background-size: 320px;

  @include mq($desk-start) {
    background-image: url("./assets/images/img_reveal.jpg");
    background-position-y: $topBackgroundImageMed; // POSITION Y WIDE DESKTOP
    background-size: 1300px;
  }
  @media all and (min-width: $desk-wide-start) and (min-height: 700px) {
    background-image: url("./assets/images/img_reveal.jpg");
    background-size: 1600px;
    background-position-y: $topBackgroundImage; // POSITION Y WIDE DESKTOP
  }
}

.bg-blurred {
  width: 100%;
  max-width: 1920px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;

  @include mq($desk-start) {
    height: 100vh;
  }
  @media all and (min-width: $desk-wide-start) and (min-height: 700px) {
    height: 100vh;
  }

  opacity: 1;
  will-change: opacity;
  transition: opacity $revealSpeedHalf ease;
  background-color: white;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position-x: center;
    background-repeat: no-repeat;
    opacity: 1;
    background-color: #216745;
    background-image: url("./assets/images/img_start_mobile.jpg");
    background-position-y: $topBackgroundImageSm; // POSITION Y WIDE DESKTOP
    background-size: 320px;

    @include mq($desk-start) {
      background-color: #1b573a;
      background-image: url("./assets/images/img_start.jpg");
      background-position-y: $topBackgroundImageMed; // POSITION Y WIDE DESKTOP
      background-size: 1300px;
    }
    @media all and (min-width: $desk-wide-start) and (min-height: 700px) {
      background-color: #1b573a;
      background-image: url("./assets/images/img_start.jpg");
      background-size: 1600px;
      background-position-y: $topBackgroundImage; // POSITION Y WIDE DESKTOP
    }
  }

  .reveal-fade & {
    opacity: 0;
  }
}

.bg-reveal {
  position: absolute;
  left: 50%;
  top: $revealTopOffsetSm;
  width: $revealStartWidthSm;
  height: $revealStartWidthSm;
  transform: translateX(-50%);
  margin-top: 0;
  border-radius: 50%;

  opacity: 1;
  will-change: width, height, opacity, margin;
  transition: all $revealSpeedHalf $revealEase $revealDelay;

  //cut out effect
  background-position: 50% -100px;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
  &:before {
    transform: scale(1);
    border-width: $revealBorderWidthSm;
    border-color: rgba(27, 87, 58, 0.8);
    border-style: solid;

    will-change: border-width, transform;
    transition: border-width $revealDelay * 1.5 ease-in,
      transform $revealSpeedHalf $revealEase $revealDelay;
  }
  &:after {
    transform: scale(0);
    opacity: 1;
    visibility: visible;
    border-width: $revealBorderWidthSm;
    border-color: rgba(27, 87, 58, 0.8);
    border-style: solid;

    will-change: transform, opacity;
    transition: $revealSpeedTwoThirds $revealEase $revealDelay;
  }

  // @include mq($lap-start) {
  //   top: $revealTopOffsetMed;
  //   width: $revealStartWidthMed;
  //   height: $revealStartWidthMed;

  //   //cut out effect
  //   background-position: 50% -80px;

  //   &:before {
  //     border-width: $revealBorderWidthMed;
  //   }
  //   &:after {
  //     border-width: $revealBorderWidthMed;
  //   }
  // }

  @include mq($desk-start) {
    top: $revealTopOffsetMed;
    width: $revealStartWidth;
    height: $revealStartWidth;

    //cut out effect
    background-position: 50% -50px;

    &:before {
      border-width: $revealBorderWidth;
    }
    &:after {
      border-width: $revealBorderWidth;
    }
  }

  @media all and (min-width: $desk-wide-start) and (min-height: 700px) {
    top: $revealTopOffset;
    width: $revealStartWidth;
    height: $revealStartWidth;

    //cut out effect
    background-position: 50% (-$revealTopOffset / 2);

    &:before {
      border-width: $revealBorderWidth;
    }
    &:after {
      border-width: $revealBorderWidth;
    }
  }

  .is-activated & {
    width: $revealEndWidthSm;
    height: $revealEndWidthSm;
    margin-top: -218px; // (-$revealAnimShift + $topBackgroundImageMed);
    background-position: 50% (-($revealTopOffsetSm - $revealAnimShiftSm));

    &:before {
      transform: scale(0.75);
      border-width: 0px;
    }
    &:after {
      transform: scale(0.75);
      opacity: 0.1;
      visibility: hidden;
    }

    a {
      opacity: 0;
    }
    // @include mq($lap-start) {
    //   width: $revealEndWidthMed;
    //   height: $revealEndWidthMed;
    //   margin-top: -318px; // (-$revealAnimShiftSm + $topBackgroundImageMed);
    //   background-position: 50% (-($revealTopOffsetMed - $revealAnimShiftMed));
    // }
    @include mq($desk-start) {
      width: $revealEndWidth;
      height: $revealEndWidth;
      margin-top: -516px;
      background-position: 50% (-($revealTopOffset - $revealAnimShift));
    }

    @media all and (min-width: $desk-wide-start) and (min-height: 700px) {
      width: $revealEndWidth;
      height: $revealEndWidth;
      margin-top: (-$revealAnimShift + $topBackgroundImage);
      background-position: 50% (-($revealTopOffset - $revealAnimShift));
    }
  }

  // Fading out reveal circle
  .reveal-fade & {
    opacity: 0;
  }

  // Reveal transition complete
  .transition-complete & {
    //display:none;
  }
}
.teaser-arrow {
  font-size: 16px;
  display: block;
  text-decoration: none;
  color: white !important;
  background-color: $lw-green;
  width: 2em;
  text-align: center;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 2;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.001);
  }

  @include mq($lap-start) {
    font-size: 20px;
  }

  will-change: opacity;
  transition: opacity $revealSpeedHalf $revealEase;

  .teaser-disc {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $lw-green;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    margin-left: -1.25em;
    margin-top: -1.25em;
    transform: scale(0.8);
    border: 2px solid white;
  }

  .teaser-arrows {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0.8);

    @include mq($lap-start) {
      transform: scale(1);
    }
  }

  [class^="teaser-arrow-"] {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -5px;
    margin-top: -5px;

    svg {
      display: block;
    }
  }
  .teaser-arrow-up {
    transform: translateY(5px);
  }
  .teaser-arrow-down {
    transform: translateY(-5px);
  }
  .teaser-arrow-right {
    transform: translateX(-5px);
  }
  .teaser-arrow-left {
    transform: translateX(5px);
  }

  .teaser-label {
    @include font-size(14);
    font-weight: bold;
    position: absolute;
    opacity: 1;
    color: $lw-green-medium;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    will-change: opacity;
    transition: 1s ease-in;
    line-height: 1.2;
    min-width: 9em;

    animation-name: anim-opacity2;
    animation-duration: 3.6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    //animation-fill-mode: forwards;

    @include mq($lap-start) {
      top: 70px;
      min-width: 20em;
    }
    @include mq($desk-start) {
      top: 100px;
    }
  }
}

// Pulse animation for main teaser
.teaser-main {
  span:not(.teaser-label),
  svg {
    pointer-events: none;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(#ffffff, 0.5);
    opacity: 0;
    animation-name: anim-opacity, anim-scale;
    animation-duration: 3.6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    pointer-events: none;
  }
  &:after {
    box-shadow: 0 0 0 2px rgba(#ffffff, 0.5);
    animation-delay: 0.3s;
  }
}

// CSS Animation Definitions
@keyframes anim-opacity {
  0%,
  33.3333% {
    opacity: 0;
  }
  13.3333%,
  20% {
    opacity: 1;
  }
}
@keyframes anim-opacity2 {
  0%,
  100% {
    opacity: 0;
  }
  33%,
  66% {
    opacity: 1;
  }
}
@keyframes anim-scale {
  0% {
    transform: scale3d(0.5, 0.5, 1);
  }
  33.3333% {
    transform: scale3d(1.4, 1.4, 1);
  }
}
@keyframes anim-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#test {
  font-size: 9px;

  @include mq($lap-start) {
    font-size: 30px;
  }
}
